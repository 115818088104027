var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("h2", { staticClass: "mt-2 mb-4 titleColor--text" }, [
        _vm._v("Ajuda - Chamados"),
      ]),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "pl-0 pr-0 pt-0" },
            [
              _c("TableSearch", {
                attrs: { label: "Pesquisar...", delay: 0 },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _c("v-spacer"),
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.tickets,
              "footer-props": {
                "items-per-page-options": [10, 25, 50],
              },
              "sort-by": "id",
              "sort-desc": false,
              search: _vm.search,
            },
          }),
          _c("div", { staticClass: "doc-card" }, [
            _c("iframe", {
              staticClass: "doc-iframe",
              attrs: {
                src: "https://docs.google.com/document/d/e/2PACX-1vQuOCnWRllWeFRxC-To0ZakJdYqfSBoRf_XcL4xz6PV8fisvcJIm0n3j9l9wYCyPTxxLfuwlEq3BTNe/pub?embedded=true",
              },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }