<template>
  <v-main>
    <h2 class="mt-2 mb-4 titleColor--text">Ajuda - Chamados</h2>
    <v-card>
      <v-card-title class="pl-0 pr-0 pt-0">
        <TableSearch v-model="search" label="Pesquisar..." :delay="0"></TableSearch>
        <v-spacer />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="tickets"
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
        }"
        sort-by="id"
        :sort-desc="false"
        :search="search"
      >
        <!-- <template v-slot:item.actions="{ item }">
          <v-btn icon title="Editar" :to="`/help-desk/chamado/${item.id}`">
            <v-icon small>mdi-history</v-icon>
          </v-btn>
        </template> -->
      </v-data-table>
      <div class="doc-card">
        <iframe
          class="doc-iframe"
          src="https://docs.google.com/document/d/e/2PACX-1vQuOCnWRllWeFRxC-To0ZakJdYqfSBoRf_XcL4xz6PV8fisvcJIm0n3j9l9wYCyPTxxLfuwlEq3BTNe/pub?embedded=true"
        />
      </div>
    </v-card>
  </v-main>
</template>
<script>
import interactionService from '@/services/interactionService.js';
import { mapState } from 'vuex';
export default {
  components: {
    TableSearch: () => import('../commons/TableSearch.vue')
  },

  data() {
    return {
      search: '',
      headers: [
        { text: 'Protocolo', align: 'start', value: 'id' },
        { text: 'Assunto', align: 'start', value: 'properties.content' },
        { text: 'Data de abertura', value: 'properties.createDateFormatted' },
        { text: 'Situação', value: 'properties.status' },
        //{ text: 'Ações', value: 'actions', align: 'center', sortable: false, width: '10%' },
      ],

      tickets: [],
    };
  },

  computed: {
    ...mapState('user', {
      user: (state) => state.user,
    }),
  },

  beforeMount: function () {
    this.refresh();
  },

  methods: {
    async loadTickets() {
      var response = await interactionService.ticket.findByUserMail(this.getUserMail());
      this.tickets = response.data;
      this.replaceStatusProperty();
    },

    getUserMail: function () {
      return { ...this.user }.emailAddress;
    },

    replaceStatusProperty: function () {
      this.tickets.forEach((ticket) => {
        switch (ticket.properties.status) {
          case 'NEW':
            ticket.properties.status = 'Aberto';
            break;
          case 'WAITING_CONTACT':
            ticket.properties.status = 'Em análise';
            break;
          case 'WAITING_FOR_US':
            ticket.properties.status = 'Em análise';
            break;
          case 'CLOSED':
            ticket.properties.status = 'Encerrado';
            break;
        }
      });
    },

    async refresh() {
      var fiveSeconds = 5000;
      while (this.$route.name == 'Chamados') {
        this.loadTickets();
        await new Promise((r) => setTimeout(r, fiveSeconds));
      }
    },
  },
};
</script>

<style scoped>
.doc-card {
  background: #f7f7fd;
  padding: 32px;
}
.doc-iframe {
  width: 840px;
  height: 16180px;
  margin-left: auto;
  margin-right: auto;
  border: 0px;
  display: block;
}
</style>
